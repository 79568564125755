import React from "react"
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import { Helmet } from 'react-helmet'
import Layout from "../components/Layout"

import { aniRainbowText } from '../components/TextRainbow'

const BlogTitle = styled.h3`
    display: block;
    margin: 0 0 1rem 0;
`
const BlogDate = styled.h4`
    display: block;
    margin: 0 0 1.5rem 0;
    color: #999;
`
const NavigatorContainer = styled.div`
    font-size: 66pt;
    margin: 2.5rem 0 0 0;
    text-align: center;
    padding: 0.5rem 0;

    > a {
      text-decoration: none;
      display: inline-block;
      width: 50%;

      &:hover {
        background: -webkit-linear-gradient(
          120deg,
          #BE416D,
          #ABBE41,
          #41BE92,
          #5441BE
        );

        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        animation: 10s linear 0s infinite normal none running ${aniRainbowText};
      }

      @media (max-width: 992px) {
        display: block;
        width: 100%;
      }
    }
`
const NavigatorArticleTitle = styled.p`
    font-size: 8pt;
    display: block;
`

const BlogPost = (props: any) => {
    const { title, date, meta_desc, html, next, previous } = props.pageContext;

    const hasPrevious = (previous && previous.frontmatter.type === "blog" && !previous.frontmatter.hidden)
    const hasNext = (next && next.frontmatter.type === "blog" && !next.frontmatter.hidden)
    
    console.log(previous, next)

    const formatDate = (date: string) => {
        return new Date(date).toLocaleString('en-us',{month:'long', year:'numeric'})
    }

    return (
      <Layout>
          <Helmet>
              <title>{title} | Harry Denley</title>
              <meta name="twitter:card" content="summary" />
              <meta name="twitter:site" content="@sniko_" />
              <meta name="twitter:title" content={`${title} - Harry Denley`} />
              {meta_desc && <meta name="twitter:description" content={`${meta_desc}`} />}
              {props.data?.file?.childImageSharp?.fixed && <meta name="twitter:image" content={`https://harrydenley.com/${props.data.file.childImageSharp.fixed.src}`} />}
          </Helmet>
        <BlogTitle>
            <Link to="/">~/</Link>&nbsp;
            {title}
        </BlogTitle>
        <BlogDate>
            Published: { formatDate(date) }
        </BlogDate>
        <div dangerouslySetInnerHTML={{ __html: html }} />

        <NavigatorContainer>
            {hasPrevious &&
                <>
                  <Link to={'/' + previous.frontmatter.slug}>
                    &#9664;
                    <NavigatorArticleTitle>
                      {previous.frontmatter.title}
                    </NavigatorArticleTitle>
                  </Link>
                </>
            }

            {hasNext &&
                <>
                  <Link to={'/' + next.frontmatter.slug}>
                    &#9654;
                    <NavigatorArticleTitle>
                      {next.frontmatter.title}
                    </NavigatorArticleTitle>
                  </Link>
                </>
            }
        </NavigatorContainer>
      </Layout>
    );
  };
  
export default BlogPost;

export const query = graphql`
   query ($MetaImgRegex: String) {
    file(absolutePath: {regex: $MetaImgRegex}) {
      childImageSharp {
        fixed(width: 800) {
          ...GatsbyImageSharpFixed
        }
      }
    }
  }
`;